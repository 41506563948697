@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}

body {
  background-color: #eef2f7;
  color: #fff;
  background-image: linear-gradient(to right, #eef2f7, #eef2f7);
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.main {
  max-width: 1024px;
  margin: 0 auto;
}

.head {
  max-width: 520px;
  margin: 0 auto;
  padding: 10px;
  padding-top: 0;
}

.search {
  height: 100px;
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.search-box {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease;
}

.search-box:focus-within {
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.3);
}

.search-box input {
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  outline: 0;
  height: 40px;
  background-color: white;
  padding: 0 10px;
  min-width: 350px;
}

.search-box button {
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  outline: 0;
  height: 40px;
  background-color: white;
  padding: 0 10px;
  cursor: pointer;
}

.cards > div:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.cards > div:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.cards > div:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.cards > div:nth-child(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.cards > div:nth-child(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.cards > div:nth-child(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.cards > div:nth-child(7) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.cards > div:nth-child(8) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.cards > div:nth-child(9) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.cards > div:nth-child(10) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.cards > div:nth-child(11) {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

.cards > div:nth-child(12) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.cards > div:nth-child(13) {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
}

.cards > div:nth-child(14) {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

.cards > div:nth-child(15) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.cards > div:nth-child(16) {
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}

.cards > div:nth-child(17) {
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s;
}

.cards > div:nth-child(18) {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

.cards > div:nth-child(19) {
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s;
}

.cards > div:nth-child(20) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.cards > div:nth-child(21) {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s;
}

.cards > div:nth-child(22) {
  -webkit-animation-delay: 2.2s;
          animation-delay: 2.2s;
}

.cards > div:nth-child(23) {
  -webkit-animation-delay: 2.3s;
          animation-delay: 2.3s;
}

.cards > div:nth-child(24) {
  -webkit-animation-delay: 2.4s;
          animation-delay: 2.4s;
}

.cards > div:nth-child(25) {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

.card {
  -webkit-animation: poof 0.5s;
          animation: poof 0.5s;
  overflow: hidden;
  transition: all 0.3s ease;
}

.card:hover {
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.3);
}

.card img {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.card img:hover {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}

.popup {
  -webkit-animation: poof 0.5s;
          animation: poof 0.5s;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -ms-grid;
  display: grid;
  place-content: center;
  padding: 5px;
  z-index: 10;
}

.popup-content {
  position: relative;
  background-color: #3c3e44;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 600px;
  min-height: 220px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.6) 0px 2px 4px -1px;
}

@media (max-width: 600px) {
  .popup-content {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.popup-content .popup-close {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1;
}

.popup-content .popup-close:hover {
  color: red;
}

.popup-content .popup-a {
  -webkit-flex: 2 1;
          flex: 2 1;
  width: 100%;
}

.popup-content .popup-a img {
  width: 100%;
  height: 100%;
  margin: 0px;
  object-position: center center;
  object-fit: cover;
}

.popup-content .popup-b {
  -webkit-flex: 3 1;
          flex: 3 1;
  position: relative;
  padding: 0.75rem;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.popup-content .popup-b h2 {
  margin: 19px 0;
}

.popup-content .popup-b .popup-details span:first-child {
  font-weight: bold;
}

.popup-content .popup-b .popup-details span:last-child {
  color: #c9db62;
}

.cards {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(150px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content);
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;
  min-height: 75vh;
}

.loader,
.error {
  grid-column: 1/-1;
  text-align: center;
  font-size: 24px;
  -webkit-animation: poof 0.5s;
          animation: poof 0.5s;
  color: #16acc9;
}

.btns {
  display: -webkit-flex;
  display: flex;
}

.btn {
  -webkit-flex: 1 1;
          flex: 1 1;
  cursor: pointer;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 22px;
  text-decoration: none;
  margin: 20px;
  color: #fff;
  position: relative;
  display: inline-block;
}

.btn:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 1px 0px 0px;
}

.next {
  background-color: #2ecc71;
  box-shadow: 0px 5px 0px 0px #15b358;
}

.next:hover {
  background-color: #48e68b;
}

.back {
  background-color: #e74c3c;
  box-shadow: 0px 5px 0px 0px #ce3323;
}

.back:hover {
  background-color: #ff6656;
}

/* Keyframes  */
@-webkit-keyframes poof {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes poof {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
/*# sourceMappingURL=App.css.map */
